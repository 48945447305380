<template>
  <div 
    v-show="showDetail"
    class="my-5"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-0 pl-0' : 'px-0'">
    <v-card outlined class="box-shadow pa-5 pb-5 rounded-lg">
      <v-subheader class="py-0" style="height: 10px;">
        <v-btn
          @click="closeDetails()"
          text
          small
          color="#1B59F8"
          class="text-capitalize mb-2">
          <v-icon left>mdi-chevron-left</v-icon>
          Kembali
        </v-btn>
      </v-subheader>
      <v-card-title class="body-1 px-5 mt-1 text-second pt-0 pb-1">
        {{ title }}
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <Export
            class=""
            :disabled="$store.state.loading"
            :file_type="'xlsx'"
            :btnText="'Export Excel'"
            :btnIcon="'mdi-microsoft-excel'"
            :btnColor="'teal'"
            :url="this.export.url"
            :title="this.export.fileName"
            ref="base-export-excel"
          />
            <!-- :url="type == 'buyer' ? urls : url" -->
          <!-- <Export
            class=" mr-2"
            :disabled="$store.state.loading"
            :file_type="'pdf'"
            :btnText="'Export PDF'"
            :btnIcon="'mdi-file-pdf-box'"
            :btnColor="'#f40f02'"
            :url="'dashboard-top-product/pdf'"
            :title="'Data Item Top'"
            ref="base-export-pdf"
          /> -->
        </div>
      </v-card-title>
      <v-card-subtitle class="caption px-5 mt-1 text-third">
        <b>{{ filtered }}</b>
      </v-card-subtitle>
      <v-card-text class="px-5" v-if="type != 'buyer'">
        
        <div v-if="items.length > 0">
          <v-data-table
            :headers="headers"
            :items="items"
            disable-sort
            hide-default-footer
            disable-pagination 
            :options.sync="options"
            :loading="process.run"
            loading-text="Sedang memuat"
            no-data-text="Data belum ada"
            no-results-text="Data belum ada"
            sort-by-text="Urutkan berdasarkan"
            :items-per-page="10"
            height="calc(100vh - 476px)"
            fixed-header
            :footer-props="footerProps"
            flat
            class="elevation-0 rounded-lg"
            :header-props="{ 'sort-icon': 'mdi-sort-descending' }">
            <!-- HEADER -->
            <template v-slot:[`header.merchant_name`]="{ header }">
              <span style="color: #FA91AD">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.product_name`]="{ header }">
              <span style="color: #FA91AD">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.product_name_realtime`]="{ header }">
              <span style="color: #FA91AD">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.product_price`]="{ header }">
              <span style="color: #FA91AD">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.transaction_count`]="{ header }">
              <span style="color: #FA91AD">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.transaction_total`]="{ header }">
              <span style="color: #FA91AD">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.product_category_name`]="{ header }">
              <span style="color: #FA91AD">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.created_at`]="{ header }">
              <span style="color: #FA91AD">{{ header.text }}</span>
            </template>

            <!-- ITEMS -->
            <template v-slot:[`item.merchant_name`]="{item}">
              <div>{{ item.store_name }}</div>
              <!-- <div class="caption text-third">{{ item.merchant_address }}</div> -->
            </template>
            <template v-slot:[`item.product_category_name`]="{item}">
              <div>{{ item.name }}</div>
              <div class="caption text-third">{{ item.store_name }}</div>
            </template>
            <template v-slot:[`item.product_name`]="{item}">
              <div>{{ item.name }}</div>
              <div class="caption text-third">{{ item.store_name }}</div>
            </template>
            <template v-slot:[`item.product_name_realtime`]="{item}">
              <div>{{ item.product_name }}</div>
            </template>
            <template v-slot:[`item.created_at`]="{item}">
              <div>{{ item.created_at | date }}</div>
              <div class="caption text-third">{{ item.created_at | time }}</div>
            </template>
            <template v-slot:[`item.product_price`]="{item}">
              {{ item.grand_total | price }}
            </template>
            <template v-slot:[`item.transaction_count`]="{item}">
              {{ item.transaction_count | num_format }} Transaksi
            </template>
            <template v-slot:[`item.transaction_count_product`]="{item}">
              <b>{{ item.transaction_count | num_format }}</b> <span class="caption">{{ item.unit }}</span>
            </template>
            <template v-slot:[`item.transaction_total`]="{item}">
              {{ item.transaction_total | price }}
            </template>
          </v-data-table>

          <Pagination 
            @changeLimit="limit = $event; initialize()" 
            @changePage="page = $event; initialize($event)" 
            :model="pagination"
            v-show="pagination.data.total_page > 0"/>
        </div>

        <div v-else class="text-center body-1 font-weight-bold mt-8" style="height:calc(100vh - 300px)">
          <v-img
            contain
            class="mx-auto mb-7"
            width="250"
            :src="require('@/assets/icon/img_empty_data.png')">
          </v-img>
          <div>
            Data transaksi belum tersedia
          </div>
        </div>
      </v-card-text>

      <!-- COMPONENT BUYER SECTION -->
      <v-card-text class="px-5" v-else>

        <v-tabs
          color="#d31145"
          left
          v-model="tabs">
          <v-tab>Data Pelanggan</v-tab>
          <v-tab>Data Transaksi Pelanggan Per Produk</v-tab>

          <v-tab-item>
            <div v-if="items.length > 0">
              <v-data-table
                :fixed-header="true"
                :headers="headersTab"
                :items="items"
                :loading="process.run"
                loading-text="Sedang memuat"
                no-data-text="Data belum ada"
                no-results-text="Data belum ada"
                sort-by-text="Urutkan berdasarkan"
                hide-default-footer
                disable-sort
                disable-pagination 
                :options.sync="options"
                height="calc(100vh - 476px)"
                :footer-props="footerProps"
                flat
                class="elevation-0 rounded-lg"
                :header-props="{ 'sort-icon': 'mdi-sort-descending' }">
                <!-- HEADER -->
                <template v-slot:[`header.merchant_name`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.customer_name`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.product_name`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.product_price`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.transaction_count`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.transaction_total`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.product_category_name`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.created_at`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>

                <!-- ITEMS -->
                <template v-slot:[`item.customer_name`]="{item}">
                  <div class="body-2 text-first">{{ item.name }}</div>
                  <div class="caption text-third">{{ item.store_name }}</div>
                </template>
                <template v-slot:[`item.transaction_total`]="{item}">
                  {{ item.transaction_total | price }}
                </template>
                <template v-slot:[`item.transaction_count`]="{item}">
                  {{ item.transaction_count | num_format }} Transaksi
                </template>
              </v-data-table>

              <Pagination 
                @changeLimit="limit = $event; initialize()" 
                @changePage="page = $event; initialize($event)" 
                :model="pagination"
                v-show="pagination.data.total_page > 0"/>
            </div>

            <div v-else class="text-center body-1 font-weight-bold mt-8" style="height:calc(100vh - 300px)">
              <v-img
                contain
                class="mx-auto mb-7"
                width="250"
                :src="require('@/assets/icon/img_empty_data.png')">
              </v-img>
              <div>
                Data transaksi belum tersedia
              </div>
            </div>
          </v-tab-item>

          <v-tab-item>

            <div v-if="itemsbyCustomer.length > 0">
              <v-data-table
                :fixed-header="true"
                :headers="headersTab"
                :items="itemsbyCustomer"
                :loading="process.run"
                loading-text="Sedang memuat"
                no-data-text="Data belum ada"
                no-results-text="Data belum ada"
                sort-by-text="Urutkan berdasarkan"
                hide-default-footer
                disable-sort
                disable-pagination 
                :options.sync="optionsbyCustomer"
                height="calc(100vh - 476px)"
                flat
                class="elevation-0 rounded-lg">
                <!-- HEADER -->
                <template v-slot:[`header.customer_name`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.product_name`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.calc_product_selling_price`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.product_price`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.product_qty`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.product_unit`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.transaction_total`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.transaction_count`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>
                <template v-slot:[`header.product_subtotal_price`]="{ header }">
                  <span style="color: #FA91AD">{{ header.text }}</span>
                </template>

                <!-- ITEMS -->
                <template v-slot:[`item.customer_name`]="{item}">
                  <div class="body-2 text-first">{{ item.customer_name }}</div>
                  <div class="caption text-third">{{ item.store_name }}</div>
                </template>
                 <template v-slot:[`item.product_name`]="{item}">
                  {{ item.product_name }}
                </template>
                <template v-slot:[`item.calc_product_selling_price`]="{item}">
                  {{ item.calc_product_selling_price | price }}
                </template>
                <template v-slot:[`item.product_qty`]="{item}">
                  <b>{{ item.product_qty | num_format }}</b> {{ item.product_unit }}
                </template>
                <template v-slot:[`item.product_subtotal_price`]="{item}">
                  {{ (item.product_subtotal_price) | price }}
                </template>
              </v-data-table>

              <Pagination 
                @changeLimit="limitbyCustomer = $event; initializeByCustomer()" 
                @changePage="pagebyCustomer = $event; initializeByCustomer($event)" 
                :model="paginationbyCustomer"
                v-show="paginationbyCustomer.data.total_page > 0"/>
            </div>

            <div v-else class="text-center body-1 font-weight-bold mt-8" style="height:calc(100vh - 300px)">
              <v-img
                contain
                class="mx-auto mb-7"
                width="250"
                :src="require('@/assets/icon/img_empty_data.png')">
              </v-img>
              <div>
                Data transaksi belum tersedia
              </div>
            </div>
          </v-tab-item>

        </v-tabs>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { get } from '@/service/Axios'
import Empty from "@/components/Empty";
import Export from "@/components/Export";
import Pagination from "@/components/Pagination";
export default {
  props: {
    showDetail: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    headers: {
      type: Array,
      default: []
    },
    url: {
      type: String,
      default: ""
    },
    filtered: {
      type: String,
      default: ""
    }
  },
  components: {
    Empty,
    Export,
    Pagination
  },
  data () {
    return {
      EXPORT_URL_REF_BY_TYPE: {
        realtime: {
          url: "eo/report/sales_order/export_excel?sort=-date",
          fileName: "Data Transaksi Merchant",
        },
        buyer: {
          0: {
            url: "eo/report/customer/export_excel?sort=transaction_count",
            fileName: "Data Pelanggan",
          },
          1: {
            url: "eo/report/sales_order/by-customer/export_excel?sort=inc_id",
            fileName: "Data Transaksi Pelanggan Per Produk",
          },
        },
        sales: {
          url: "eo/report/store/export_excel?sort=transaction_count",
          fileName: "Data Sales Merchant",
        },
        product_category: {
          url: "eo/report/product/category/export_excel?sort=transaction_count",
          fileName: "Data Product Category",
        },
        product: {
          url: "eo/report/product/export_excel?sort=transaction_count",
          fileName: "Data Item Product",
        },
      },

      items: [],
      itemsbyCustomer: [],
      
      limit: 10,
      page: 1,
      options:{},
      pagination: {
        data: {},
        page: ""
      },
      selected: {
        menu: 0,
        pagination: 0
      },


      // byCustomer 
      limitbyCustomer: 10,
      pagebyCustomer: 1,
      optionsbyCustomer:{},
      paginationbyCustomer: {
        data: {},
        page: ""
      },
      selectedbyCustomer: {
        menu: 0,
        pagination: 0
      },
      // byCustomer 

      tabs: null,
      headersTab: [],
      urls: "",
      export: {
        url: "",
        fileName: "",
      },
      totalData: 0,
      footerProps: {
        "show-current-page": true,
        "show-first-last-page": true,
        "items-per-page-options": [10, 20, 30, 50, 100],
        "items-per-page-text": "Data per halaman",
        "page-text": "{0} - {1} dari total {2}",
      },
      show: false,
      emptyData: false,
      process: {
        run: false
      }
    }
  },
  watch: {
    tabs: function(val) {

      if (this.EXPORT_URL_REF_BY_TYPE[this.type] !== undefined) {
        if (this.type == "buyer") {
          this.export.url = `${this.EXPORT_URL_REF_BY_TYPE[this.type][val].url}&event_id=${this.event_selected.id}`;
          this.export.fileName = `${this.EXPORT_URL_REF_BY_TYPE[this.type][val].fileName}`;
        } else {
          this.export.url = `${this.EXPORT_URL_REF_BY_TYPE[this.type].url}&event_id=${this.event_selected.id}`;
          this.export.fileName = `${this.EXPORT_URL_REF_BY_TYPE[this.type].fileName}`;
        }
      }

      if (this.type == "buyer") {
        if (val == 0) {
          this.url = `eo/report/customer?event_id=${this.event_selected.id}&sort=transaction_count`
          this.headersTab = [
            {
              text: "Nama Pelanggan",
              value: "customer_name",
              align: "start"
            },
            {
              text: "Jumlah Pembelian",
              value: "transaction_count",
              align: "center"
            },
            {
              text: "Total Transaksi",
              value: "transaction_total",
              align: "end"
            },
          ]
          this.initialize()
        }else {
          this.headersTab = [
            {
              text: "Nama Pelanggan",
              value: "customer_name",
              align: "start"
            },
            {
              text: "Nama Produk",
              value: "product_name",
              align: "start"
            },
            {
              text: "Qty",
              value: "product_qty",
              align: "center"
            },
            {
              text: "Harga Produk",
              value: "calc_product_selling_price",
              align: "end"
            },
            {
              text: "Total Harga Produk",
              value: "product_subtotal_price",
              align: "end"
            },
            // {
            //   text: "Tanggal",
            //   value: "created_at",
            //   align: "center"
            // },
          ]
          this.initializeByCustomer(1)
        }
      }
    },
    "options.page": function() {
      this.initialize();
    },
    "options.limit" : function() {
      this.initialize()
    },
    'selected.pagination': function(val) {
      this.initialize(val+1)
    },
    "optionsbyCustomer.page": function() {
      this.initializeByCustomer();
    },
    "optionsbyCustomer.limit" : function() {
      this.initializeByCustomer()
    },
    'selectedbyCustomer.pagination': function(val) {
      this.initializeByCustomer(val+1)
    },
    options:{
      handler(){
        this.initialize()
      },
      deep:true
    },
    optionsbyCustomer:{
      handler(){
        this.initializeByCustomer()
      },
      deep:true
    }
  },
  computed: {
    event_selected () {
      return JSON.parse(localStorage.getItem("event_selected"))
    }
  },
  mounted () {
    this.tabs = 0
    this.initialize()
  },
  methods: {
    showData(state) {
      this.$emit('show', state)
    },
    closeDetails(state) {
      this.showDetail = false
      this.$emit('close', false)
    },
    async initialize(paging) {
      this.process.run = true;

      let sort = "";
      let dir = "";

      if (
        this.options.sortBy &&
        this.options.sortBy.length > 0
      ) {
        sort = this.options.sortBy[0];
      } else {
        sort = "";
      }

      if (
        this.options.sortDesc &&
        this.options.sortDesc.length > 0
      ) {
        if (this.options.sortDesc[0]) {
          dir = "DESC";
        } else {
          dir = "ASC";
        }
      } else {
        dir = "";
      }

      await get(this.url, {
        params: {
          page: paging,
          limit: this.limit,
        }
      }).then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.items = res.results.data
          this.totalData = res.results.pagination.total_data
          this.pagination.data = res.results.pagination
          this.pagination.page = res.results.pagination.total_page < this.page ? 1 : ""

          this.process.run = false
        }
      })

      this.process.run = false;
    },

    async initializeByCustomer(paging) {
      this.process.run = true;

      await get(`eo/report/sales_order/by-customer?event_id=${this.event_selected.id}`, {
        params: {
          page: paging,
          limit: this.limitbyCustomer,
        }
      }).then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.itemsbyCustomer = res.results.data
          this.totalData = res.results.pagination.total_data
          // this.total = res.data.total
          this.paginationbyCustomer.data = res.results.pagination
          this.paginationbyCustomer.page = res.results.pagination.total_page < this.page ? 1 : ""
          this.process.run = false
        }
      })

      this.process.run = false;
    }
  }
}
</script>