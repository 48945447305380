<template>
  <div class=""
    :class="$vuetify.breakpoint.name === 'lg' ? 'pl-9 pr-8' : 'px-5'" style="margin-left: 12%;">
    <v-btn
      small
      text
      color="#d31145"
      class="text-capitalize px-0 mb-1"
      to="/">
      <v-icon left>mdi-chevron-left</v-icon>
      kembali ke Dashboard
    </v-btn>

    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.state">
      <div>{{ snackbar.text }}</div>
    </v-snackbar>

    <div>

      <v-row>
        <v-col cols="12" md="6">
          <span class="caption">Pilih Event</span>
          <v-select
            outlined
            dense
            class="mt-2"
            color="#d31145"
            v-model="event_id"
            :items="event"
            item-text="name"
            item-value="id">
          </v-select>
        </v-col>
        <v-col cols="12" md="6">
          <span class="caption">Tuliskan email owner merchant yang ingin Anda undang dalam event</span>
          <v-combobox
            outlined
            dense
            hide-selected
            multiple
            small-chips
            attach
            v-model="email_array"
            color="#d31145"
            class="mt-2"
            placeholder="Tuliskan email Owner Merchant yang ingin anda undang dalam event"
            :items="generate"
            :search-input.sync="search_email">
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                v-if="item"
                v-bind="attrs"
                color="grey lighten-1"
                :input-value="selected"
                small>
                <span class="pr-2">
                  {{ item }}
                </span>
                <v-icon
                  small
                  @click="parent.selectItem(item)">
                  mdi-close
                </v-icon>
              </v-chip>
            </template>
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Tekan <kbd>enter</kbd> untuk menambahkan "<strong>{{ search_email }}</strong>"
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-combobox>
        </v-col>
      </v-row>



      <v-scroll-y-transition>
        <v-alert
          dense
          outlined
          text
          icon="mdi-alert"
          color="error"
          class="mb-1 mt-5"
          v-show="error.validation.length > 0"
        >
          <div class="subtitle-2" v-html="error.validation"></div>
        </v-alert>
      </v-scroll-y-transition>

      <v-btn
        color="#d31145"
        class="white--text text-capitalize float-right"
        v-show="event_id != ''"
        :loading="process.form"
        @click="sendInvitation()">
        Kirimkan undangan event
      </v-btn>

      <p class="body-1 px-5 font-weight-bold text-second mt-3">
        <span>
          DAFTAR MERCHANT 
          <span  v-if="event_id">
            ({{ totalData }} Merchant Terdaftar)
          </span>
        </span>
        <v-btn
          color="#d31145"
          small
          elevation="0"
          v-if="event_id"
          :loading="process.form"
          class="white--text ml-5"
          @click="inviteAllMerchant()">
          Kirim undangan ke semua pengguna sodapos
        </v-btn>
      </p>
      <v-divider class="pb-5"></v-divider>
      <!-- <v-row class="" v-if="invitation.length > 0">
        <v-col cols="8" style="overflow: auto; height: calc(100vh - 350px)">
          <div v-for="(invitate, index) in invitation" :key="index">
            <v-list-item two-line dense>
              <v-list-item-content>
                <v-list-item-title>{{ invitate.email }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip v-if="invitate.status == 'waiting'" color="warning" small class="text-capitalize">
                    {{ invitate.status }}
                  </v-chip>
                  <v-chip v-else color="success" small class="text-capitalize">
                    {{ invitate.status }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      @click="resendInvitation(invitate)"
                      v-on="on"
                      icon
                      :loading="process.resend"
                      v-bind="attrs"
                      color="teal">
                      <v-icon>
                        mdi-email-sync
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Kirim ulang email</span>
                </v-tooltip>
              </v-list-item-icon>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </div>
        </v-col>
      </v-row> -->
      <div v-if="invitation.length > 0">
        <v-data-table
          :headers="headers"
          :items="invitation"
          disable-sort
          hide-default-footer
          disable-pagination 
          :options.sync="options"
          :loading="process.run"
          loading-text="Sedang memuat"
          no-data-text="Data belum ada"
          no-results-text="Data belum ada"
          sort-by-text="Urutkan berdasarkan"
          :items-per-page="10"
          height="calc(100vh - 476px)"
          fixed-header
          :footer-props="footerProps"
          flat
          class="elevation-0 rounded-lg"
          :header-props="{ 'sort-icon': 'mdi-sort-descending' }">
          <!-- HEADER -->
          <template v-slot:[`header.email`]="{ header }">
            <span style="color: #FA91AD;">{{ header.text }}</span>
          </template>
          <template v-slot:[`header.status`]="{ header }">
            <span style="color: #FA91AD;">{{ header.text }}</span>
          </template>
          <template v-slot:[`header.action`]="{ header }">
            <span style="color: #FA91AD;">{{ header.text }}</span>
          </template>

          <!-- ITEMS -->
          <template v-slot:[`item.email`]="{item}">
            <div class="text-first">{{ item.email }}</div>
          </template>

          <template v-slot:[`item.status`]="{item}">
            <v-chip v-if="item.status == 'waiting'" color="warning" small class="text-capitalize">
                {{ item.status }}
              </v-chip>
              <v-chip v-else color="success" small class="text-capitalize">
                {{ item.status }}
              </v-chip>
          </template>

          <template v-slot:[`item.action`]="{item}">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  @click="resendInvitation(item)"
                  v-on="on"
                  icon
                  :loading="process.resend"
                  v-bind="attrs"
                  color="teal">
                  <v-icon>
                    mdi-email-sync
                  </v-icon>
                </v-btn>
              </template>
              <span>Kirim ulang email</span>
            </v-tooltip>
          </template>
          
        </v-data-table>

        <Pagination 
          @changeLimit="limit = $event; getInvitation()" 
          @changePage="page = $event; getInvitation($event)" 
          :model="pagination"
          v-show="pagination.data.total_page > 0"/>
      </div>
      <div v-else class="text-center subtitle-2 mt-7">
        <v-img
          contain
          class="mx-auto mb-3"
          width="280"
          :src="require('@/assets/icon/img_empty_data.png')">
        </v-img>
        <p class="text-h6 mt-7">
          Silahkan pilih event untuk melihat daftar merchant yang diundang
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios"
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination
  },
  data() {
    return {
      search_email: '',
      countdown_get: 0,
      event_id: 0,
      events: [],
      email_array: [],
      merchant_invitate: [],

      headers: [
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Status Konfirmasi',
          value: 'status',
          align: 'center'
        },
        {
          text: 'Kirim Ulang undangan',
          value: 'action',
          align: 'center'
        },
      ],
      invitation:[],
      limit: 10,
      page: 1,
      options:{},
      pagination: {
        data: {},
        page: ""
      },
      selected: {
        menu: 0,
        pagination: 0
      },
      totalData: 0,
      footerProps: {
        "show-current-page": true,
        "show-first-last-page": true,
        "items-per-page-options": [10, 20, 30, 50, 100],
        "items-per-page-text": "Data per halaman",
        "page-text": "{0} - {1} dari total {2}",
      },

      generate: [],
      process: {
        run: false,
        form: false,
        resend: false,
      },
      snackbar: {},
      error: {
        validation: ""
      }
    }
  },
  watch: {
    "options.page": function() {
      this.getInvitation();
    },
    "options.limit" : function() {
      this.getInvitation()
    },
    'selected.pagination': function(val) {
      this.getInvitation(val+1)
    },
    options:{
      handler(){
        this.getInvitation()
      },
      deep:true
    },
  },
  computed: {
    event() {
      return JSON.parse(localStorage.getItem("event"))
    },
    event_selected() {
      return JSON.parse(localStorage.getItem("event_selected"))
    }
  },
  watch: {
    'event_id': function(val){
      this.event_id = val
      this.getInvitation()
    }
  },
  beforeMount() {
  },
  mounted() {
    this.getInvitation()
    // setInterval(() => {
    //   this.getMerchantInvitation()
    // }, 10000)
  },
  methods: {
    async getMerchantInvitation() {
      this.process.run = true;
      await get(`eo/general/merchant`, {
        params: {
          limit: 100000
        }
      }).then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.merchant_invitate = res.results.data
          this.process.run = false
          this.countdown_get = 1
        }else{
          this.process.run = false
        }
      })
    },
    async getInvitation(paging) {
      this.process.run = true;
      await get(`eo/event/invite`, {
        params: {
          event_id: this.event_id,
          page: paging,
          limit: this.limit,
        }
      }).then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.invitation = res.results.data
          this.process.run = false
          this.countdown_get = 1
          this.pagination.data = res.results.pagination
          this.totalData = res.results.pagination.total_data
        }else{
          this.process.run = false
        }
      })
    },
    async sendInvitation() {
      this.process.form = true;
      await post(`eo/event/invite`, {
        data: {
          email_array: this.email_array,
          event_id: this.event_id,
          is_send_bool: true,
        }
      }).then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.process.form = false
          this.snackbar = {
            state: true,
            color: 'success',
            text: res.message
          }
          
          this.email_array = []
          this.getInvitation()
        }else {
          this.error.validation = res.message
          this.process.form = false
          this.snackbar = {
            state: true,
            color: 'error',
            text: res.message
          }
        }
      })
    },
    async resendInvitation(item) {
      this.process.resend = true;
      await post(`eo/event/resend_invite`, {
        data: {
          id: item.id
        }
      }).then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.process.resend = false
          this.getInvitation()
          this.snackbar = {
            state: true,
            color: 'success',
            text: res.message
          }
        }else {
          this.error.validation = res.message
          this.process.resend = false
          this.snackbar = {
            state: true,
            color: 'error',
            text: res.message
          }
        }
      })
    },
    async inviteAllMerchant() {
      this.process.form = true;
      await post(`eo/event/resend_bulk_invite`, {
        data: {
          event_id: this.event_id,
        }
      }).then((response) => {
        let res = response.data
        if (res.status == 200) {
          this.process.form = false
          this.snackbar = {
            state: true,
            color: 'success',
            text: res.message
          }
          
          this.email_array = []
          this.getInvitation()
        }else {
          this.error.validation = res.message
          this.process.form = false
          this.snackbar = {
            state: true,
            color: 'error',
            text: res.message
          }
        }
      })
    }
  },
}
</script>