<template>
  <div>
    <v-card-text v-if="$vuetify.breakpoint.name !== 'xs'">
      <v-row class="justify-center" align="center">
        <v-col cols="12" class="d-flex align-center px-0 justify-end">
          <!-- ==== LIMIT DATA ==== -->
          <div class="mr-4 ml-5 mt-2" style="font-size:0.83rem; color:#7b7b7b;">
            Baris per halaman
          </div>
          <div>
            <v-select
              dense
              :items="limit_options"
              item-text="label"
              item-value="label"
              v-model="limit"
              color="#7b7b7b"
              hide-details
              v-on:change="changeLimit()"
              style="width: 50px; font-size:0.83rem; color:#7b7b7b;"
            >
            </v-select>
          </div>

          <!-- ==== STATUS JUMLAH DATA ==== -->
          <div style="font-size:0.83rem; color:#7b7b7b;" class="mt-2 mr-4 ml-5">
            {{ (pagination.data.current - 1) * limit + 1 }} -
            {{
              (pagination.data.current - 1) * limit +
                1 +
                pagination.data.total_display -
                1
            }}
            dari {{ pagination.data.total_data }} Data
          </div>
          <v-btn
            color="#7b7b7b"
            elevation="0"
            height="30"
            small
            @click="firstPage"
            icon
            :disabled="page < 2 || isNaN(page)"
            class="mt-2 text-capitalize"
          >
            <v-icon> mdi-page-first</v-icon>
          </v-btn>
          <v-btn
            color="#7b7b7b"
            elevation="0"
            height="30"
            small
            icon
            :disabled="page < 2 || isNaN(this.page)"
            class="mt-2  "
            @click="changePage(page - 1)"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <div class="mx-4 ">
            <v-text-field
              dense
              hide-details
              v-model="page"
              :disabled="pagination.data.total_page == 1"
              min="1"
              style="width: 50px; font-size:0.83rem;"
              id="halaman"
              type="number"
              color="#7b7b7b"
              v-on:input="changePage(page)"
            >
            </v-text-field>
          </div>
          <v-btn
            color="#7b7b7b"
            elevation="0"
            height="30"
            small
            icon
            :disabled="page === pagination.data.total_page || isNaN(this.page)"
            class="mt-2 text-capitalize"
            @click="changePage(page + 1)"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            color="#7b7b7b"
            elevation="0"
            height="30"
            small
            icon
            @click="lastPage"
            class="mt-2 text-capitalize"
            :disabled="page === pagination.data.total_page || isNaN(page)"
          >
            <v-icon> mdi-page-last</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text v-if="$vuetify.breakpoint.name === 'xs'">
      <v-row class="justify-start" align="center">
        <v-col
          cols="8"
          class="d-flex align-center pa-1 mt-1 justify-start"
        >
          <!-- ==== LIMIT DATA ==== -->
          <!-- <div
            class="mr-4 ml-5 "
            style="font-size:10px; color:#7b7b7b;"
          >
            Baris per halaman
          </div> -->
          <div style="font-size:11px;">
            {{ (pagination.data.current - 1) * limit + 1 }} -
            {{
              (pagination.data.current - 1) * limit +
                1 +
                pagination.data.total_display -
                1
            }}
            dari {{ pagination.data.total_data }} Data
          </div>
        </v-col>

        <v-col
          cols="4"
          class="d-flex align-center pa-0 mt-1 justify-end"
        >
        </v-col>

        <v-col
          cols="4"
          class="d-flex align-center pa-1 mt-1 justify-start"
        >
          <!-- ==== LIMIT DATA ==== -->
          <!-- <div
            class="mr-4 ml-5 "
            style="font-size:10px; color:#7b7b7b;"
          >
            Baris per halaman
          </div> -->
          <div>
            <v-select
              dense
              :items="limit_options"
              item-text="label"
              item-value="label"
              v-model="limit"
              color="#7b7b7b"
              hide-details
              v-on:change="changeLimit()"
              style="width: 60px; font-size:11px; color:#7b7b7b;"
            >
            </v-select>
          </div>
        </v-col>

        <v-col
          cols="8"
          class="d-flex align-center pa-0 mt-1 justify-end"
        >

          <v-btn
            color="#7b7b7b"
            elevation="0"
            height="30"
            small
            @click="firstPage"
            icon
            :disabled="page < 2 || isNaN(page)"
            class="mt-2 text-capitalize"
          >
            <v-icon> mdi-page-first</v-icon>
          </v-btn>
          <v-btn
            color="#7b7b7b"
            elevation="0"
            height="30"
            small
            icon
            :disabled="page < 2 || isNaN(this.page)"
            class="mt-2  "
            @click="changePage(page - 1)"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <div class="mx-4 ">
            <v-text-field
              dense
              hide-details
              v-model="page"
              :disabled="pagination.data.total_page == 1"
              min="1"
              style="width: 50px; font-size:0.83rem;"
              id="halaman"
              type="number"
              color="#7b7b7b"
              v-on:input="changePage(page)"
            >
            </v-text-field>
          </div>
          <v-btn
            color="#7b7b7b"
            elevation="0"
            height="30"
            small
            icon
            :disabled="page === pagination.data.total_page || isNaN(this.page)"
            class="mt-2 text-capitalize"
            @click="changePage(page + 1)"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-btn
            color="#7b7b7b"
            elevation="0"
            height="30"
            small
            icon
            @click="lastPage"
            class="mt-2 text-capitalize"
            :disabled="page === pagination.data.total_page || isNaN(page)"
          >
            <v-icon> mdi-page-last</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
</div>
</template>

<script>
export default {
  name: "pagination-data",
  data: () => ({
    page: 1,
    limit: 10,
    total_page: null,
    current: 1,
    limit_options: [
      {
        label: 10,
      },
      {
        label: 20,
      },
      {
        label: 50,
      },
      {
        label: 100,
      },
    ],
  }),
  computed: {
    pagination() {
      if (this.$attrs.model.page === 1) {
        this.page = 1;
      }
      return this.$attrs.model;
    },
  },
  methods: {
    changeLimit() {
      this.page = 1;
      this.$emit("changeLimit", this.limit);
    },
    changePage(numberPage) {
      this.page = parseInt(numberPage);
      if (numberPage > this.pagination.data.total_page || this.page < 1) {
        numberPage = 1;
        setTimeout(() => {
          this.page = 1;
        }, 10);
      }
      if (!isNaN(this.page)) {
        this.$emit("changePage", numberPage);
      }
    },

    lastPage() {
      this.page = parseInt(this.pagination.data.total_page);
      this.$emit("lastPage", this.page);
    },

    firstPage() {
      this.page = 1;
      this.$emit("firstPage", 1);
    },
  },
};
</script>
